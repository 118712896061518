<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-dialog v-model="show_generate_bank_outcome" persistent width="900">
      <v-card>
        <v-card-text class="pt-3">
          <div style="display: flex">
            <div style="flex: 0 0 160px; margin-right: 16px">
              <date_input v-model="generate_outcome_data.general.date" label="Дата: " :time="true" required id="date1"/>
            </div>
            <div style="flex: 0 0 200px; margin-right: 16px">
              <select_input
                  v-model="generate_outcome_data.general.chart_of_account_id"
                  :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                  :computed-getter="'get_chart_of_accounts_list_select'"
                  :input-value-as-value="false"
                  :item-search="'text'"
                  :show-on-focus="false"
                  :row_num="0"
                  label="Рахунок (311-314)"
                  :show-select-on-focus="false"
                  :action-filter="'@field=type@;@start-with=S@31@@@;@field=type@;@start-with=S@БНК'"
                  :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'План рахунків',
                                element_title: 'Рахунок',
                                action_filter: '@field=code@;@start-with=S@31',
                                show: true
                              }"
                  clearable
              />
            </div>
            <div style="flex: 0 0 320px; margin-right: 16px">
              <select_input
                  v-model="generate_outcome_data.general.bank_account_id"
                  :computed-action="'SELECT_BANK_ACCOUNT_LIST_ELEMENTS'"
                  :computed-getter="'get_bank_account_list_select'"
                  :input-value-as-value="false"
                  :item-search="'text'"
                  :show-on-focus="false"
                  label="Банківський рахунок: "
                  :select-button-object="{
                                name: 'BANK_ACCOUNT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список банківських рахунків',
                                element_title: 'Банківський рахунок',
                                show: true
                              }"
                  clearable
              />
            </div>
            <div style="flex: 0 0 120px; margin-right: 16px">
              <select_input
                  v-model="generate_outcome_data.general.currency_id"
                  :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                  :computed-getter="'get_currency_list_select'"
                  :input-value-as-value="false"
                  :item-search="'text'"
                  :show-on-focus="false"
                  label="Валюта: "
                  :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                  clearable
              />
            </div>
          </div>
        </v-card-text>
        <v-card-text class="pt-1 accounting-document">
          <div class="document-table" style="margin-top: 6px !important;">
            <div class="document-table-body">
              <div class="document-table-header">
                <div class="document-table-row">
                  <div class="document-table-col" style="flex: 0 0 42px">

                  </div>
                  <div class="document-table-col" style="flex: 0 0 260px">
                    Рахунок б/о
                  </div>
                  <div class="document-table-col" style="flex: 0 0 260px">
                    Одержувач
                  </div>
                  <div class="document-table-col" style="flex: 0 0 200px">
                    Договір
                  </div>
                  <div class="document-table-col" style="flex: 0 0 90px">
                    Сума
                  </div>
                </div>
              </div>
              <div class="document-table-row">
                <div class="document-table-col" style="flex: 0 0 42px">
                  <v-checkbox hide-details :ripple="false"
                              class="mt-1 pt-0"
                              color="success" v-model="generate_outcome_data.salary.create"/>
                </div>
                <div class="document-table-col" style="flex: 0 0 260px">
                  Заробітна плата (661, 663)
                </div>
                <div class="document-table-col" style="flex: 0 0 260px">
                  <select_input
                      v-model="generate_outcome_data.salary.contractor_id"
                      :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                      :computed-getter="'get_contractor_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :show-additional-field="true"
                      :row_num="generate_outcome_data.salary.row_num"
                      :show-select-on-focus="false"
                      small
                      :disabled="!generate_outcome_data.salary.create"
                      transparent
                      :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                showAdditionalField: true
                              }"
                      clearable
                      :required="true"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 200px">
                  <select_input
                      v-model="generate_outcome_data.salary.contract_id"
                      :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                      :computed-getter="'get_contractor_contract_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="generate_outcome_data.salary.row_num"
                      small
                      transparent
                      :disabled="!generate_outcome_data.salary.create"
                      :owner_id="generate_outcome_data.salary.contractor_id"
                      :action-props="{owner_id: generate_outcome_data.salary.contractor_id}"
                      :show-select-on-focus="false"
                      :sync-action-props="true"
                      :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: generate_outcome_data.salary.contractor_id},
                                sync_action_props: true
                              }"
                      :required="true"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 90px">
                  {{ generate_outcome_data.salary.sum | formatToFixed | formatNumber }}
                </div>
              </div>
              <div class="document-table-row"
                   v-for="(charge, idx) in generate_outcome_data.taxes"
                   :key="`g-o-${idx}`"
              >
                <div class="document-table-col" style="flex: 0 0 42px">
                  <v-checkbox hide-details :ripple="false"
                              class="mt-1 pt-0"
                              color="success" v-model="charge.create"/>
                </div>
                <div class="document-table-col" style="flex: 0 0 260px">
                  <select_input
                      v-model="charge.chart_of_account_id"
                      :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                      :computed-getter="'get_chart_of_accounts_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="charge.row_num"
                      :show-select-on-focus="false"
                      :show-search-on-focus="true"
                      small
                      transparent
                      disabled
                      :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                      :required="true"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 260px">
                  <select_input
                      v-model="charge.contractor_id"
                      :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                      :computed-getter="'get_contractor_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :show-additional-field="true"
                      :row_num="charge.row_num"
                      :show-select-on-focus="false"
                      small
                      :disabled="!charge.create"
                      transparent
                      :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                showAdditionalField: true
                              }"
                      clearable
                      :required="true"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 200px">
                  <select_input
                      v-model="charge.contract_id"
                      :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                      :computed-getter="'get_contractor_contract_list_select'"
                      :input-value-as-value="false"
                      :item-search="'text'"
                      :show-on-focus="false"
                      :row_num="charge.row_num"
                      small
                      :disabled="!charge.create"
                      transparent
                      :owner_id="charge.contractor_id"
                      :action-props="{owner_id: charge.contractor_id}"
                      :show-select-on-focus="false"
                      :sync-action-props="true"
                      :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: charge.contractor_id},
                                sync_action_props: true
                              }"
                      :required="true"
                  />
                </div>
                <div class="document-table-col" style="flex: 0 0 90px">
                  {{ charge.sum | formatToFixed | formatNumber }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small depressed color="success" @click="generateBankOutcomeDocuments"
                 :loading="generate_outcome_data.general.loading">
            Сформувати платіжки
          </v-btn>
          <v-btn small depressed @click="closeGenerateOutcome">
            Закрити
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-0 pb-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="document-header" style="flex: 0" :class="payed ? 'document-disabled' : ''">
        <div class="document-header-row px-0 pt-2 pb-2">
          <div style="flex: 0 0 160px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required @userChange="onDateChange" id="date"/>
          </div>
          <div style="flex: 0 0 110px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 140px; margin-right: 16px">
            <month_input v-model="month" label="Місяць нарахування: " required @userChange="onMonthChange" id="month"/>
          </div>
          <div style="flex: 0 0 108px; margin-right: 16px">
            <v-checkbox
                v-model="by_bank" label="По банку" color="success"
                hide-details class="mt-4 pt-0"
                style="font-size: 12px !important;"
            />
          </div>
          <div style="flex: 0 0 108px; margin-right: 16px; align-self: flex-end">
            <v-btn small @click.stop="fillDocument"
                   style="height: 30px;"
                   :disabled="!date || !month || !operation"
            >Заповнити
            </v-btn>
          </div>
          <div style="flex: 0 0 180px; align-self: flex-end">
            <v-btn small @click.stop="recalcDocument"
                   style="height: 30px; width: 100%"
                   :disabled="!date || !month || !operation"
            >Перерахувати
            </v-btn>
          </div>
        </div>
        <div class="document-header-row px-0 pt-1 pb-3">
          <div style="flex: 0 0 286px; margin-right: 16px">
            <select_input
                v-model="subdivision_id"
                :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                :computed-getter="'get_subdivision_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Підрозділ"
                :show-select-on-focus="false"
                :required="false"
                :select-button-object="{
                          name: 'SUBDIVISION_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список підрозділ',
                          element_title: 'Підрозділ',
                          show: true
                        }"
            />
          </div>
          <div style="flex: 0 0 264px; margin-right: 16px">
            <select_input
                v-model="operation"
                :clearable="true"
                :import-getter="{ name: 'salary_to_pay_operation' }"
                :input-value-as-value="false"
                label="Операція: "
            />
          </div>
          <div style="flex: 0 0 108px; align-self: flex-end;  margin-right: 16px;">
            <v-btn small @click.stop="clearDocument"
                   style="height: 30px; width: 100%"
                   :disabled="!date || !month || !operation"
            >Очистити
            </v-btn>
          </div>
          <div style="flex: 0 0 180px; align-self: flex-end">
            <v-btn small @click.stop="openGenerateOutcome"
                   style="height: 30px; width: 100%; pointer-events: auto !important; user-select: auto !important; opacity: 1 !important;"
                   :disabled="!date || !month || !operation || isNew"
            >
              Створити платіжки
            </v-btn>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab v-if="showTabCharge">
                  Нарахування
                </v-tab>
                <v-tab v-if="showTabTaxes">
                  ПДФО
                </v-tab>
                <v-tab v-if="showTabContribution">
                  Внески
                </v-tab>
                <v-tab>
                  Результат
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="showTabCharge"
                            :class="payed ? 'document-disabled' : ''">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month)"
                           class="success mr-2" :disabled="!this.date || !this.month"
                           @click.stop="addCharge"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 280px">
                            Працівник
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            Період розрахунку
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Група
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px">
                            Рахунок б/о
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px">
                            До випплати
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px" v-if="!shortTables">
                            Сума брудними
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in charges"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ charges.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteCharge(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 280px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="!!charge.person_id"
                              transparent
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              @selectChange="onPersonChangeCharge"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <month_input :row_num="1" v-model="charge.month" small transparent/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />

                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px">
                          <select_input
                              v-model="charge.chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterDebitChartOfAccountChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_to_pay" v-decimal transparent
                                        small
                                        :input_style="`font-weight: bold; ${getSumColor(charge.result)}`"
                                        :required="false"
                                        @userChange="onChargeChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px" v-if="!shortTables">
                          <number_input :row_num="charge.row_num" v-model="charge.sum_to_pay_dirty" v-decimal
                                        transparent small
                                        :input_style="`font-weight: bold; ${getSumColor(charge.result)}`"
                                        :required="false"
                                        @userChange="onChargeChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 760px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px">
                            {{ footer_total.charge.sum_to_pay | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 140px; padding-left: 10px"
                               v-if="!shortTables">
                            {{ footer_total.charge.sum_to_pay_dirty | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="showTabTaxes"
                            :class="payed ? 'document-disabled' : ''">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month)"
                           class="success mr-2" :disabled="!this.date || !this.month"
                           @click.stop="addPDFO"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col one-row" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 260px">
                            Працівник
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 135px">
                            Період розрахунку
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 140px">
                            Рахунок б/о
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Група
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 100px" v-if="!shortTables">
                            База
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 90px">
                            ПДФО
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 80px">
                            Військовий <br> збір
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in tax_pdfo"
                           :key="`tax_pdfo-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ tax_pdfo.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deletePDFO(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 260px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="!!charge.person_id"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              transparent
                              @selectChange="onPersonChangePdfo"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 135px">
                          <month_input :row_num="charge.row_num" v-model="charge.month" small transparent/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 140px">
                          <select_input
                              v-model="charge.chart_of_account_id"
                              :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                              :computed-getter="'get_chart_of_accounts_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список рахунків бух.обліку',
                                element_title: 'Рахунок бух.обліку',
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterDebitChartOfAccountChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />

                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 100px" v-if="!shortTables">
                          <number_input :row_num="charge.row_num" v-model="charge.base" v-decimal small transparent
                                        input_style="font-style: oblique; font-weight: 500"
                                        :required="false"
                                        @userChange="onPdfoChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 90px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal small transparent
                                        input_style="font-weight: bold;"
                                        :required="false"
                                        @userChange="onPdfoResultChange"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 80px">
                          <number_input :row_num="charge.row_num" v-model="charge.result_military" v-decimal small
                                        transparent
                                        input_style="font-weight: bold;"
                                        :required="false"
                                        @userChange="onPdfoResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="flex: 0 0 715px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; padding-left: 10px"
                               v-if="!shortTables">
                            {{ footer_total.tax_pdfo.base | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 90px; padding-left: 10px">
                            {{ footer_total.tax_pdfo.result | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px; padding-left: 10px">
                            {{ footer_total.tax_pdfo.result_military | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" v-if="showTabContribution"
                            :class="payed ? 'document-disabled' : ''">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="!!(this.date && this.month)"
                           class="success mr-2" :disabled="!this.date || !this.month"
                           @click.stop="addContribution"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 260px">
                            Працівник
                          </div>
                          <div class="document-table-col" style="flex: 0 0 135px">
                            Період розрахунку
                          </div>
                          <div class="document-table-col" style="flex: 0 0 170px">
                            Вид внеску
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 110px">
                            Група
                          </div>
                          <div class="document-table-col" style="flex: 0 0 80px" v-if="!shortTables">
                            Процент
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px" v-if="!shortTables">
                            База
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px">
                            Результат
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in contribution"
                           :key="`contribution-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ contribution.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteContribution(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 260px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="!!charge.person_id"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              transparent
                              @selectChange="onPersonChangeContribution"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 135px">
                          <month_input :row_num="1" v-model="charge.month" small transparent/>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 170px">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true
                              }"
                              transparent
                              @selectChange="onContributionTaxChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <select_input
                              v-model="charge.accrual_group"
                              :import-getter="{ name: 'accrual_group_select' }"
                              :input-value-as-value="false"
                              :row_num="charge.row_num"
                              small
                              transparent
                          />

                        </div>
                        <div class="document-table-col" style="flex: 0 0 80px" v-if="!shortTables">
                          <number_input :row_num="charge.row_num" v-model="charge.tariff" v-decimal small transparent
                                        @userChange="onContributionChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px" v-if="!shortTables">
                          <number_input :row_num="charge.row_num" v-model="charge.base" v-decimal small transparent
                                        input_style="font-style: oblique; font-weight: 500"
                                        @userChange="onContributionChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 110px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal small transparent
                                        :input_style="'font-weight: bold'"
                                        :required="false"
                                        @userChange="onContributionResultChange"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               style="text-align: right; padding-right: 6px"
                               :style="shortTables ? 'flex: 0 0 745px;' : 'flex: 0 0 825px;'"
                          >
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px; padding-left: 10px"
                               v-if="!shortTables">
                            {{ footer_total.contribution.base | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px; padding-left: 10px">
                            {{ footer_total.contribution.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-0" style="height: 100%;" :class="payed ? 'document-disabled' : ''">
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 260px">
                            Працівник
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px" v-if="showTabCharge">
                            До оплати
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px" v-if="showTabTaxes">
                            ПДФО
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px" v-if="showTabTaxes">
                            Військ.збір
                          </div>
                          <div class="document-table-col" style="flex: 0 0 110px" v-if="showTabContribution">
                            ЄСВ
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in result"
                           :key="`result-${charge.row_num}`"
                      >
                        <div class="document-table-col one-row" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ result.indexOf(charge) + 1 }}
                          </div>
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 260px">
                          <select_input
                              v-model="charge.person_id"
                              :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                              :computed-getter="'get_personal_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :required="true"
                              :disabled="true"
                              :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true
                                                      }"
                              small
                              transparent
                              @selectChange="onPersonChangeContribution"
                          />
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 110px" v-if="showTabCharge">
                          {{ charge.to_pay | formatNumber }}
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 110px" v-if="showTabTaxes">
                          {{ charge.pdfo | formatNumber }}
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 110px" v-if="showTabTaxes">
                          {{ charge.military | formatNumber }}
                        </div>
                        <div class="document-table-col one-row" style="flex: 0 0 110px" v-if="showTabContribution">
                          {{ charge.contribution | formatNumber }}
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col one-row"
                               style="flex: 0 0 330px; text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 110px; padding-left: 10px"
                               v-if="showTabCharge">
                            {{ footer_total.result.to_pay | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col one-row " style="flex: 0 0 110px; padding-left: 10px"
                               v-if="showTabTaxes">
                            {{ footer_total.result.pdfo | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 110px; padding-left: 10px"
                               v-if="showTabTaxes">
                            {{ footer_total.result.military | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col one-row" style="flex: 0 0 110px; padding-left: 10px"
                               v-if="showTabContribution">
                            {{ footer_total.result.contribution | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  GET_PERSONAL_LIST_ELEMENTS,
  SELECT_TAXES_LIST_ELEMENTS,
  SELECT_ACCRUAL_LIST_ELEMENTS,
  SELECT_PERSONAL_LIST_ELEMENTS,
  SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS,
  SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS,
  SELECT_SUBDIVISION_LIST_ELEMENTS,
  CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
  UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
  REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
  CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapActions, mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import salaryChargeAPI from "@/utils/axios/accounting/documents/salary_charge"
import salaryToPayAPI from "@/utils/axios/accounting/documents/salary_to_pay"
import {maxBy, uniq} from "lodash";
import {beginOfMonth, format_date_from_python} from "@/utils/icons";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'salary_to_pay_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Salary_To_Pay",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    month_input: () => import("@/components/accounting/input/document_month_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
  },
  data() {
    return {
      show_generate_bank_outcome: false,
      generate_outcome_data: {
        general: {
          date: null,
          bank_account_id: null,
          chart_of_account_id: null,
          currency_id: null,
          document_salary_to_pay_id: null,
          loading: false
        },
        salary: {},
        taxes: []
      },
      month: this.item.month || null,
      subdivision_id: this.item.subdivision_id || null,
      by_bank: this.item.by_bank || false,
      credit_chart_of_account: this.item.credit_chart_of_account || null,
      isNew: this.item.id === undefined || this.item.id == null,
      operation: this.item.operation || null,
      taxes: [],
      payed: false,
      persons: [],
      charges: [],
      result: [],
      contribution: [],
      tax_pdfo: [],
      social_privilege_data: {
        'privilege_169_1_1_100': {base_counter: 1, children: false},
        'privilege_169_1_2_100': {base_counter: 1, children: true},
        'privilege_169_1_3a_150': {base_counter: 1.5, children: true},
        'privilege_169_1_3b_150': {base_counter: 1.5, children: true},
        'privilege_169_1_3d_150': {base_counter: 1.5, children: false},
        'privilege_169_1_3e_150': {base_counter: 1.5, children: false},
        'privilege_169_1_3g_150': {base_counter: 1.5, children: false},
        'privilege_169_1_3g1_150': {base_counter: 1.5, children: false},
        'privilege_169_1_4a_200': {base_counter: 2, children: false},
        'privilege_169_1_4b_200': {base_counter: 2, children: false},
        'privilege_169_1_4g_200': {base_counter: 2, children: false},
        'privilege_169_1_4g1_200': {base_counter: 2, children: false},
        'privilege_169_1_4v_200': {base_counter: 2, children: false},
      },
      footer_total: {
        charge: {sum_to_pay: 0, sum_to_pay_dirty: 0},
        contribution: {base: 0, base_vocation: 0, base_add: 0, result: 0, result_add: 0},
        tax_pdfo: {base: 0, result: 0, result_military: 0},
        result: {to_pay: 0, pdfo: 0, military: 0, contribution: 0},
      }
    }
  },
  methods: {
    ...mapActions(
        {
          getPersonal: GET_PERSONAL_LIST_ELEMENTS,
        }
    ),
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_SALARY_TO_PAY_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    openGenerateOutcome() {
      this.generateOutcomeData()
      this.show_generate_bank_outcome = true
    },
    closeGenerateOutcome() {
      this.generate_outcome_data = {
        general: {
          date: null,
          bank_account_id: null,
          chart_of_account_id: null,
          currency_id: null,
          document_salary_to_pay_id: null,
          loading: false
        },
        salary: {},
        taxes: []
      }
      this.show_generate_bank_outcome = false
    },
    generateOutcomeData() {
      const code_651 = (this.charts.find(item => item.code === '651') || {}).id
      const code_6411 = (this.charts.find(item => item.code === '6411') || {}).id
      const code_642 = (this.charts.find(item => item.code === '642') || {}).id
      const code_311 = (this.charts.find(item => item.code === '311') || {}).id

      const contribution_sum = this.contribution.reduce((acc, item) => {
        return acc + item.result
      }, 0)
      const pdfo_sum = this.tax_pdfo.reduce((acc, item) => {
        return acc + item.result
      }, 0)
      const military_sum = this.tax_pdfo.reduce((acc, item) => {
        return acc + item.result_military
      }, 0)
      const salary_sum = this.charges.reduce((acc, item) => {
        return acc + item.sum_to_pay
      }, 0)

      this.generate_outcome_data = {
        general: {
          date: this.date,
          bank_account_id: null,
          chart_of_account_id: code_311,
          currency_id: null,
          document_salary_to_pay_id: this.itemId,
          loading: false
        },
        salary: {},
        taxes: []
      }

      if (this.accounting_settings) {
        const default_bank_accounting = this.$store.getters.getDefaultBankAccount

        this.generate_outcome_data.general.currency_id = this.accounting_settings?.default_currency?.value || null
        if (default_bank_accounting.id || default_bank_accounting.value) {
          this.generate_outcome_data.general.bank_account_id = (default_bank_accounting.id || default_bank_accounting.value)
        }
      }

      if (salary_sum !== 0) {
        this.generate_outcome_data.salary = {
          chart_of_account_id: null,
          sum: salary_sum,
          create: false,
          contractor_id: null,
          contract_id: null,
          row_num: 1,
          is_contribution: false
        }
      }

      if (contribution_sum !== 0) {
        this.generate_outcome_data.taxes.push(
            {
              chart_of_account_id: code_651,
              sum: contribution_sum,
              create: false,
              contractor_id: null,
              contract_id: null,
              row_num: 2,
              is_contribution: true
            }
        )
      }

      if (pdfo_sum !== 0) {
        this.generate_outcome_data.taxes.push(
            {
              chart_of_account_id: code_6411,
              sum: pdfo_sum,
              create: false,
              contractor_id: null,
              contract_id: null,
              row_num: 3,
              is_contribution: false
            }
        )
      }

      if (military_sum !== 0) {
        this.generate_outcome_data.taxes.push(
            {
              chart_of_account_id: code_642,
              sum: military_sum,
              create: false,
              contractor_id: null,
              contract_id: null,
              row_num: 4,
              is_contribution: false
            }
        )
      }


    },
    generateBankOutcomeDocuments() {
      let errors = 0

      if (!this.generate_outcome_data.general.date) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть дату для формування платіжок'})
        return
      }
      if (!this.generate_outcome_data.general.chart_of_account_id) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть рахунок бух.обліку (311-313) для формування платіжок'})
        return
      }
      if (!this.generate_outcome_data.general.bank_account_id) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть банківський рахунок для формування платіжок'})
        return
      }
      if (!this.generate_outcome_data.general.currency_id) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть валюту для формування платіжок'})
        return
      }

      if (this.generate_outcome_data.salary.create) {
        if (!this.generate_outcome_data.salary.contractor_id) {
          errors += 1
        }
        if (!this.generate_outcome_data.salary.contract_id) {
          errors += 1
        }
      }
      this.generate_outcome_data.taxes.forEach(i => {
        if (i.create) {
          if (!i.contractor_id) {
            errors += 1
          }
          if (!i.contract_id) {
            errors += 1
          }
        }
      })

      if (errors > 0) {
        this.$store.commit(ALERT_SHOW, {text: 'Заповніть рядки, котрі підкреслені червоним (Одержувач, Договір)'})
        return
      }

      this.generate_outcome_data.general.loading = true

      salaryToPayAPI.create_bank_outcome(this.generate_outcome_data)
          .then(response => response.data)
          .then(() => {
            this.$store.commit(ALERT_SHOW, {text: 'Обрані платіжки сформовані успішно', color: 'success'})
            this.clearPersonData()
            this.fetch_data_by_id()
            this.closeGenerateOutcome()
          })
          .finally(() => {
            this.closeGenerateOutcome()
          })


    },
    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data
          })
    },
    getShortName(payload) {
      if (!payload) return ''
      const spliced = payload.split(' ')

      if (spliced.length <= 3) {
        return `${spliced[0]} ${spliced[1].substring(0, 1)}. ${spliced[2].substring(0, 1)}`
      }
      if (spliced.length === 2) {
        return `${spliced[0]} ${spliced[1].substring(0, 1)}`
      }
      if (spliced.length === 1) {
        return `${spliced[0]}`
      }
    },
    getSumColor(result) {
      if ((result || 0) < 0) {
        return 'color: var(--v-error-base)'
      }
      if ((result || 0) > 0) {
        return 'color: var(--v-success-darken1)'
      }
      return 'color: inherit'
    },
    onMonthChange() {
      if (this.month && this.date) {
        this.getStartInfo()
      }
    },
    onDateChange() {
      if (this.month && this.date) {
        this.getStartInfo()
      }
    },
    getTotals() {
      this.footer_total.charge = this.charges.reduce((acc, item) => {
        acc.sum_to_pay += item.sum_to_pay || 0
        acc.sum_to_pay_dirty += item.sum_to_pay_dirty || 0
        return acc
      }, {sum_to_pay: 0, sum_to_pay_dirty: 0})
      this.footer_total.contribution = this.contribution.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.base_vocation += item.base_vocation || 0
        acc.base_add += item.base_add || 0
        acc.result += item.result || 0
        acc.result_add += item.result_add || 0
        return acc
      }, {base: 0, base_vocation: 0, base_add: 0, result: 0, result_add: 0})
      this.footer_total.tax_pdfo = this.tax_pdfo.reduce((acc, item) => {
        acc.base += item.base || 0
        acc.result += item.result || 0
        acc.result_military += item.result_military || 0
        return acc
      }, {base: 0, result: 0, result_military: 0})
      this.footer_total.result = this.result.reduce((acc, item) => {
        acc.to_pay += item.to_pay || 0
        acc.pdfo += item.pdfo || 0
        acc.military += item.military || 0
        acc.contribution += item.contribution || 0
        return acc
      }, {to_pay: 0, pdfo: 0, military: 0, contribution: 0})
    },
    getTaxTariff(tax_id, month) {
      const sorted = this.start_info.tax_tariff.filter(item => item.tax_id === tax_id && item.month <= month)
          .map(i => Object.assign({}, i)).sort((a, b) => a.month > b.month)

      return sorted.length ? sorted[sorted.length - 1].tariff || 0 : 0
    },
    getMinSalary(month) {
      const sorted = this.start_info.min_amounts.filter(item => item.month <= month)
          .map(i => Object.assign({}, i)).sort((a, b) => a.month > b.month)

      return sorted.length ? sorted[sorted.length - 1].min_salary || 0 : 0
    },
    getCostOfLife(month) {
      const sorted = this.start_info.min_amounts.filter(item => item.month <= month)
          .map(i => Object.assign({}, i)).sort((a, b) => a.month > b.month)

      return sorted.length ? sorted[sorted.length - 1].cost_of_living || 0 : 0
    },
    getPersonType(payload, payload_add) {
      if (!payload) return ''
      if (payload === 'full_time') {
        if (payload_add === 'main_work_place') {
          return 'осн.'
        }
        if (payload_add === 'combination') {
          return 'совм.'
        }
        return 'осн.'
      }
      if (payload === 'by_contract') {
        return 'дог.'
      }
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    roundUp(x) {
      return x % 10 === 0 ? x : x + 10 - x % 10
    },
    getSocialPrivilegeCalcData(privilege_type, max_salary, base, children = 0) {
      const privilege_data = this.social_privilege_data[privilege_type] || {base_counter: 0, children: false}

      let max_salary_result = (max_salary * (privilege_data.children ? children : 1)) || 0
      let base_result = (base * privilege_data.base_counter * (privilege_data.children ? children : 1)) || 0

      return {
        salary: max_salary_result,
        base: base_result
      }
    },
    round_number(value, precision) {
      return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
    },
    getStartInfo() {
      if (this.date && this.month) {
        if (!this.charts || !this.charts.length) {
          this.getChartsOfAccounts()
        }
        salaryChargeAPI.start_info({month_start: this.month, month_end: this.month})
            .then(response => response.data)
            .then(data => {
              this.start_info = Object.assign({}, data)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    },
    getStartInfoPromise() {
      return new Promise((resolve, reject) => {
        if (this.date && this.month) {
          if (!this.charts || !this.charts.length) {
            this.getChartsOfAccounts()
          }
          salaryChargeAPI.start_info({month_start: this.month, month_end: this.month})
              .then(response => response.data)
              .then(data => {
                this.start_info = Object.assign({}, data)
                resolve()
              })
              .catch(err => {
                const error = err.response.data.detail;
                this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                reject()
              })
        }
      })
    },
    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'date_start': 'Дата початку',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      let text = 'Виправте помилки: \n' + errors.join(', ') + ' - мають бути заповнені. \n'
      return {
        valid: form_valid,
        text: text
      }

    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0) {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined || item[filter_row.condition] !== 0) {
              skip = true
            }
          }

          if (item[key] !== null && item[key] !== undefined && item[key] !== 0) {
            skip = true
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument() {
      this.document_state.show = !this.document_state.show
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'month': {id: 'month', text: 'Не заповнений місяць виплати заробітної плати'},
        'operation': {id: 'operation', text: 'Не заповнена операція для виплати заробінох плати/податків'},
      }

      const table_charge = {
        'person_id': {table: 'Нарахування', text: 'Не заповнений працівник'},
        'accrual_group': {table: 'Нарахування', text: 'Не заповнена група нарахування'},
        'tax_group': {table: 'Нарахування', text: 'Не заповнена група нарахування'},
      }

      const table_contribution = {
        'person_id': {table: 'ЄСВ', text: 'Не заповнений працівник'},
        'tax_id': {table: 'ЄСВ', text: 'Не заповнений вид внеску'},
        'month': {table: 'ЄСВ', text: 'Не заповнений місяць внеску'},
        'accrual_group': {table: 'ЄСВ', text: 'Не заповнена група нарахування внеску'},
      }

      if (!this.shortTables) {
        table_contribution['tariff'] = {table: 'ЄСВ', text: 'Не заповнений процент внеску'}
      }

      const table_tax_pdfo = {
        'person_id': {table: 'ПДФО', text: 'Не заповнений працівник'},
        'month': {table: 'ПДФО', text: 'Не заповнений місяць пдфо'},
        'accrual_group': {table: 'ПДФО', text: 'Не заповнена група нарахування'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.checkDocumentTable(table_charge, 'Нарахування', 'charges')
      this.checkDocumentTable(table_contribution, 'Внески', 'contribution')
      this.checkDocumentTable(table_tax_pdfo, 'ПДФО', 'tax_pdfo')

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = true
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },

    addCharge() {
      const charge_661_id = '661'
      const chart_661 = this.charts.find(c => c.code === charge_661_id)

      this.charges.push(
          {
            person_id: null,
            month: this.month,
            chart_of_account_id: chart_661.id || null,
            sum_to_pay: 0,
            sum_to_pay_dirty: 0,
            tax_group: 'main',
            row_num: this.getNewRowNum('charges'),
          }
      )
    },
    addContribution() {
      this.contribution.push(
          {
            base: 0,
            base_add: 0,
            month: this.month,
            person_id: this.selected_person,
            result: 0,
            result_add: 0,
            row_num: this.getNewRowNum('contribution'),
            tariff: 0,
            tax_id: null
          }
      )
    },
    addPDFO() {
      const pdfo_tariff = this.getTaxTariff(this.start_info.pdfo_tax_id, this.month)
      const military_tariff = this.getTaxTariff(this.start_info.military_tax_id, this.month)

      this.tax_pdfo.push(
          {
            base: 0,
            base_main: 0,
            base_hospital: 0,
            income_type_pix_id: null,
            military_tariff: military_tariff,
            month: this.month,
            pdfo_tariff: pdfo_tariff,
            person_id: this.selected_person,
            privilege_count: 0,
            privilege_type: null,
            result: 0,
            result_main: 0,
            result_hospital: 0,
            result_military: 0,
            result_military_main: 0,
            result_military_hospital: 0,
            row_num: this.getNewRowNum('tax_pdfo')
          }
      )
    },
    onPersonChangeCharge(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      if (value) {
        this.charges.filter(i => i.person_id === value).forEach(row => {
          row.person_id = value
        })
        if (this.calcTaxes) {
          this.calcAndFillPDFO(value)
          this.calcAndFillContribution(value)
        }
        this.calcAndFillResult(value)
        this.getTotals()
      }
    },
    onPersonChangeContribution(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      if (value) {
        this.contribution.filter(i => i.person_id === value).forEach(row => {
          row.person_id = value
        })
        if (this.calcTaxes) {
          this.calcAndFillPDFO(value)
          this.calcAndFillContribution(value)
        }
        this.calcAndFillResult(value)
        this.getTotals()
      }
    },
    onPersonChangePdfo(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      if (value) {
        this.tax_pdfo.filter(i => i.person_id === value).forEach(row => {
          row.person_id = value
        })
        if (this.calcTaxes) {
          this.calcAndFillPDFO(value)
        }
        this.calcAndFillResult(value)
        this.getTotals()
      }
    },
    afterDebitChartOfAccountChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      const row = this.charges.find(i => i.row_num === payload.row_num)
      if (!row) return
      if (!payload) return

      const charge_663_id = '663'
      const chart_663 = this.charts.find(c => c.code === charge_663_id)

      if (value === chart_663.id) {
        row.tax_group = 'hospital'
      }

      if (this.calcTaxes) {
        this.calcAndFillPDFO(row.person_id)
        this.calcAndFillContribution(row.person_id)
      }
      this.calcAndFillResult(row.person_id)
      this.getTotals()
    },
    onChargeChange(payload) {
      const charge_obj = this.charges.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        if (this.calcTaxes) {
          this.calcAndFillPDFO(charge_obj.person_id)
          this.calcAndFillContribution(charge_obj.person_id)
        }
        this.calcAndFillResult(charge_obj.person_id)
        this.getTotals()
      }
    },
    onContributionChange(payload) {
      const charge_obj = this.contribution.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        charge_obj.result = this.round_number(charge_obj.base * charge_obj.tariff / 100, 2)
        this.getTotals()
      }
    },
    onContributionResultChange(payload) {
      const charge_obj = this.contribution.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        this.getTotals()
      }
    },
    onContributionTaxChange(payload) {
      const value = (payload || {}).value || (payload || {}).id || null
      if (value) {
        const row = this.contribution.find(i => i.row_num === payload.row_num)
        if (row) {
          row.tariff = this.getTaxTariff(value, row.month)
        }
      }
    },
    onPdfoChange(payload) {
      const charge_obj = this.tax_pdfo.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        if (this.calcTaxes) {
          this.calcAndFillContribution(charge_obj.person_id)
        }
        this.calcAndFillResult(charge_obj.person_id)
        this.getTotals()
      }
    },
    onPdfoResultChange(payload) {
      const charge_obj = this.tax_pdfo.find(i => i.row_num === payload.row_num) || {}
      if (charge_obj.person_id) {
        if (this.calcTaxes) {
          this.calcAndFillContribution(charge_obj.person_id)
        }
        this.calcAndFillResult(charge_obj.person_id)
        this.getTotals()
      }
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.payed = false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.month = local_data.month || null
      this.subdivision_id = local_data.subdivision_id || null
      this.by_bank = local_data.by_bank || false
      this.credit_chart_of_account = local_data.credit_chart_of_account || null
      this.operation = local_data.operation || null
      this.charges = []
      this.contribution = []
      this.result = []
      this.tax_pdfo = []
      this.itemId = local_data.id || this.item.id || null
    },
    fillDocument() {
      const payload = {
        month: this.month,
        subdivision_id: this.subdivision_id,
        document_id: this.document_id,
        date: this.date,
        operation: this.operation
      }

      salaryToPayAPI.fill_by_all(payload)
          .then(response => response.data)
          .then(data => {
            this.charges = []
            this.tax_pdfo = []
            this.contribution = []
            this.result = []

            const persons = []

            this.charges = data.charges.map((i, idx) => {
              if (!persons.includes(i.person_id)) {
                persons.push(i.person_id)
              }
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.tax_pdfo = data.taxes.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.contribution = data.contributions.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })


            this.charges.forEach(item => {
              if (this.calcTaxes) {
                this.calcAndFillPDFO(item.person_id)
                this.calcAndFillContribution(item.person_id)
              }
              this.calcAndFillResult(item.person_id)
            })
            if (!this.charges.length && this.contribution.length) {
              this.contribution.forEach(item => {
                this.calcAndFillResult(item.person_id)
              })
            }
            if (!this.charges.length && !this.contribution.length) {
              this.tax_pdfo.forEach(item => {
                this.calcAndFillResult(item.person_id)
              })
            }

            this.getTotals()
          })
    },
    recalcDocument() {
      const persons = uniq(this.charges.map(item => item.person_id))
      persons.forEach(person => {
        if (this.calcTaxes) {
          this.calcAndFillPDFO(person)
          this.calcAndFillContribution(person)
        }
        this.calcAndFillResult(person)
      })
      this.getTotals()
    },
    clearDocument() {
      this.charges = []
      this.tax_pdfo = []
      this.contribution = []
      this.result = []
      this.getTotals()
    },
    calcAndFillPDFO(person_id, calc = false) {
      const taxes = []
      let base_101 = 0
      let base_101_max = 0
      let base_101_accrual_group = null


      this.charges.filter(charge => charge.person_id === person_id).forEach(item => {
        const month = beginOfMonth(item.month || this.month)
        const pdfo_tariff = this.getTaxTariff(this.start_info.pdfo_tax_id, month)
        const military_tariff = this.getTaxTariff(this.start_info.military_tax_id, month)

        const tax_row = taxes.find(t => t.month === month
            && t.chart_of_account_id === item.chart_of_account_id
            && t.accrual_group === item.accrual_group)

        let base = 0
        if (calc) {
          base = item.result !== 0 ?
              ((item.sum_to_pay || 0) / (1 - pdfo_tariff / 100 - military_tariff / 100)) || 0
              : 0
        } else {
          if (this.calcTaxes) {
            base = item.result !== 0 ? item.sum_to_pay_dirty : 0
          } else {
            base = item.result !== 0 ? item.sum_to_pay : 0
          }
        }

        if (item.accrual_group === 'main' && this.month === item.month) {
          base_101 += (item.result || 0)

          if ((item.result || 0) >= base_101_max) {
            base_101_max = (item.result || 0)
            base_101_accrual_group = item.accrual_group
          }
        }

        if (tax_row) {
          tax_row.base += this.round_number(base, 2)
        } else {
          taxes.push(
              {
                month: month,
                base: this.round_number(base, 2),
                present: false,
                pdfo_tariff: pdfo_tariff,
                military_tariff: military_tariff,
                accrual_group: item.accrual_group,
                chart_of_account_id: item.chart_of_account_id
              }
          )
        }
      })

      this.tax_pdfo.filter(tax => tax.person_id === person_id).forEach(item => {
        const tax_row = taxes.find(t => t.month === item.month
            && t.chart_of_account_id === item.chart_of_account_id
            && t.accrual_group === item.accrual_group)

        if (tax_row) {
          tax_row.present = true
          if (item.privilege_type) {
            const min_live = this.getCostOfLife(item.month)
            const pdfo_max_salary = this.roundUp(min_live * 1.4)
            const pdfo_privilege_base = min_live / 2

            const privilege_data = this.getSocialPrivilegeCalcData(
                item.privilege_type, pdfo_max_salary, pdfo_privilege_base, item.privilege_count || 0
            )

            if (base_101_accrual_group === item.accrual_group
                && this.month === item.month
                && base_101 <= privilege_data.salary
            ) {
              const pre = base_101 - privilege_data.base >= 0 ? base_101 - privilege_data.base : 0
              item.base = tax_row.base
              item.result = this.round_number(pre * tax_row.pdfo_tariff / 100, 2)
              item.result_military = this.round_number(item.base * tax_row.military_tariff / 100, 2)
            } else {
              item.base = tax_row.base
              item.result = this.round_number(tax_row.base * tax_row.pdfo_tariff / 100, 2)
              item.result_military = this.round_number(tax_row.base * tax_row.military_tariff / 100, 2)
            }

          } else {
            item.base = tax_row.base
            item.result = this.round_number(tax_row.base * tax_row.pdfo_tariff / 100, 2)
            item.result_military = this.round_number(tax_row.base * tax_row.military_tariff / 100, 2)
          }
        } else {
          this.tax_pdfo.splice(this.tax_pdfo.indexOf(item), 1)
        }
      })

      taxes.filter(t => !t.present).forEach(row => {
        this.tax_pdfo.push(
            {
              person_id,
              base: row.base,
              month: row.month,
              pdfo_tariff: row.pdfo_tariff,
              privilege_count: 0,
              privilege_type: null,
              result: this.round_number(row.base * row.pdfo_tariff / 100, 2),
              result_military: this.round_number(row.base * row.military_tariff / 100, 2),
              row_num: this.getNewRowNum('tax_pdfo'),
              accrual_group: row.accrual_group || null,
              chart_of_account_id: row.chart_of_account_id || null
            }
        )
      })

      this.charges.filter(charge => charge.person_id === person_id).forEach(item => {
        const month = beginOfMonth(item.month || this.month)
        const base_for_dirty = this.tax_pdfo.filter(tax => tax.person_id === person_id
            && tax.chart_of_account_id === item.chart_of_account_id
            && tax.month === month
            && tax.accrual_group === item.accrual_group
        ).reduce((acc, item) => {
          if (calc) {
            return acc + item.base
          } else {
            return acc + item.result + item.result_military
          }
        }, 0)

        if (calc) {
          item.sum_to_pay = this.round_number(base_for_dirty, 2)
        } else {
          item.sum_to_pay = this.round_number(item.sum_to_pay_dirty - base_for_dirty, 2)
        }
      })
    },
    calcAndFillContribution(person_id) {
      const taxes = []
      const cripple = !!((this.personSelection.find(item => item.id === person_id) || {}).cripple_category)

      this.charges.filter(item => item.person_id === person_id).forEach(charge => {
        if (charge.tax_group && charge.accrual_group) {
          const month = beginOfMonth(charge.month || this.month)
          const t_find = taxes.find(t => t.tax_group === charge.tax_group
              && t.month === month
              && t.accrual_group === charge.accrual_group)
          if (t_find) {
            t_find.base += this.round_number((charge.sum_to_pay_dirty || 0), 2)
          } else {
            taxes.push(
                {
                  tax_group: charge.tax_group,
                  month: month,
                  base: this.round_number((charge.sum_to_pay_dirty || 0), 2),
                  base_add: 0,
                  present: false,
                  accrual_group: charge.accrual_group
                }
            )
          }
        }
      })

      taxes.forEach(item => {
        const tax_group_tax = this.start_info.esv_taxes.find(tax => tax.tax_group === item.tax_group && tax.invalid === cripple)
        if (tax_group_tax) {
          item.tax_id = tax_group_tax.id
          item.tariff = this.getTaxTariff(tax_group_tax.id, item.month)
        }
      })

      this.contribution.filter(item => item.person_id === person_id).forEach(item => {
        const new_tax_row = taxes.find(t => t.tax_id === item.tax_id
            && t.month === item.month
            && t.accrual_group === item.accrual_group)
        if (new_tax_row) {
          new_tax_row.present = true
          item.base_add = 0
          item.result_add = 0

          item.base = new_tax_row.base + item.base_add
          item.result = this.round_number(new_tax_row.base * new_tax_row.tariff / 100 + item.result_add, 2) || 0


        } else {
          this.contribution.splice(this.contribution.indexOf(item), 1)
        }
      })

      taxes.filter(t => !t.present).forEach(row => {
        this.contribution.push(
            {
              person_id,
              month: row.month,
              tax_id: row.tax_id,
              base: row.base,
              tariff: row.tariff,
              result: this.round_number(row.base * row.tariff / 100, 2) || 0,
              row_num: this.getNewRowNum('contribution'),
              accrual_group: row.accrual_group
            }
        )
      })
    },
    calcAndFillResult(person_id) {
      const charge = this.charges.filter(person => person.person_id === person_id)
          .reduce((acc, item) => acc + item.sum_to_pay, 0)
      const pdfo = this.tax_pdfo.filter(person => person.person_id === person_id)
          .reduce((acc, item) => acc + item.result, 0)
      const military = this.tax_pdfo.filter(person => person.person_id === person_id)
          .reduce((acc, item) => acc + item.result_military, 0)
      const contribution = this.contribution.filter(person => person.person_id === person_id)
          .reduce((acc, item) => acc + item.result, 0)

      // console.log(charge, pdf, military, contribution)

      const row = this.result.find(item => item.person_id === person_id)
      if (row) {
        if (charge !== 0 || pdfo !== 0 || military !== 0 || contribution !== 0) {
          row.to_pay = charge
          row.pdfo = pdfo
          row.military = military
          row.contribution = contribution
        } else {
          this.result.splice(this.result.indexOf(row), 1)
        }
      } else {
        if (charge !== 0 || pdfo !== 0 || military !== 0 || contribution !== 0) {
          this.result.push(
              {
                person_id: person_id,
                to_pay: charge,
                pdfo: pdfo,
                military: military,
                contribution: contribution,
                row_num: this.getNewRowNum('result')
              }
          )
        }

      }

    },

    deleteCharge(payload) {
      const person_id = payload.person_id
      this.charges.splice(this.charges.indexOf(payload), 1)
      if (this.calcTaxes) {
        this.calcAndFillPDFO(person_id)
        this.calcAndFillContribution(person_id)
      }
      this.calcAndFillResult(person_id)
      this.getTotals()
    },
    deleteContribution(payload) {
      const person_id = payload.person_id
      this.contribution.splice(this.contribution.indexOf(payload), 1)
      if (this.calcTaxes) {
        this.calcAndFillPDFO(person_id)
      }
      this.calcAndFillResult(person_id)
      this.getTotals()
    },
    deletePDFO(payload) {
      const person_id = payload.person_id
      this.tax_pdfo.splice(this.tax_pdfo.indexOf(payload), 1)
      if (this.calcTaxes) {
        this.calcAndFillPDFO(person_id)
      }
      this.calcAndFillResult(person_id)
      this.getTotals()
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документа заробіна плата до виплати № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    deletePersonDialog(person) {
      const payload = {
        text: `Вилучити працівника ${person.full_name} із документа?`,
        accept_button: true,
        id: 'salary_charge_delete_person',
        payload: person
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_SALARY_TO_PAY_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    get_payload_for_crud() {
      const payload = {
        date: this.date,
        month: this.month,
        by_bank: this.by_bank,
        subdivision_id: this.subdivision_id,
        operation: this.operation,
        charges: this.charges,
        taxes: this.tax_pdfo,
        contributions: this.contribution,
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_TAXES_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_ACCRUAL_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_SUBDIVISION_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_PERSONAL_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
        this.$store.dispatch(SELECT_INCOME_TYPE_PIX_LIST_ELEMENTS),
      ])
    },
    fetch_data_by_id() {
      if (this.isNew) {
        if (this.item.basic_create_id) {
          if (this.item.basic_create_type === 'DOCUMENT_SALARY_CHARGE') {
            this.getStartInfoPromise()
                .then(() => {
                  salaryChargeAPI.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.month = data.month
                        this.operation = data.is_advance ? 'advance_calculated' : 'salary'
                        this.subdivision_id = data.subdivision_id

                        this.fillDocument()
                      })
                })
          }
        }
        return
      }

      salaryToPayAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.tab = 0
            this.payed = data.payed
            this.date = data.date
            this.number = data.number
            this.month = data.month
            this.by_bank = data.by_bank
            this.subdivision_id = data.subdivision_id
            this.operation = data.operation
            this.credit_chart_of_account = data.credit_chart_of_account

            this.charges = data.charges.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.contribution = data.contributions.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.tax_pdfo = data.taxes.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.charges.forEach(item => {
              this.calcAndFillResult(item.person_id)
            })
            if (!this.charges.length && this.contribution.length) {
              this.contribution.forEach(item => {
                this.calcAndFillResult(item.person_id)
              })
            }
            if (!this.charges.length && !this.contribution.length) {
              this.tax_pdfo.forEach(item => {
                this.calcAndFillResult(item.person_id)
              })
            }
            this.getTotals()
          })
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
                  .then(() => {
                    if (!this.isNew) {
                      this.getStartInfo()
                    }
                  })
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_SALARY_TO_PAY_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              // if (payload.id === "charge_refill_id") {
              //   if (payload.answer) {
              //     this.fillDocumentByAll()
              //   }
              // }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      personSelection: 'get_personal_list',
      accounting_settings: 'getAccountingGeneralSettings'
    }),
    shortTables() {
      switch (this.operation) {
        case 'pdfo':
          return true
        case 'taxes':
          return true
        case 'contribution':
          return true
        case 'salary':
          return true
      }

      return false
    },
    calcTaxes() {
      switch (this.operation) {
        case 'advance_calculated':
          return true
        case 'advance_fixed':
          return true
        case 'advance_hospital':
          return true
        case 'advance_vocation':
          return true
      }

      return false
    },
    showTabTaxes() {
      switch (this.operation) {
        case 'advance_calculated':
          return true
        case 'advance_fixed':
          return true
        case 'advance_hospital':
          return true
        case 'pdfo':
          return true
        case 'taxes':
          return true
        case 'salary':
          return true
      }

      return false
    },
    showTabContribution() {
      switch (this.operation) {
        case 'advance_calculated':
          return true
        case 'advance_fixed':
          return true
        case 'advance_hospital':
          return true
        case 'contribution':
          return true
        case 'taxes':
          return true
        case 'salary':
          return true
      }

      return false
    },
    showTabCharge() {
      switch (this.operation) {
        case 'contribution':
          return false
        case 'taxes':
          return false
        case 'pdfo':
          return false
      }

      return true
    },

    privilege_percent() {
      if (this.privilege_type) {
        const position = this.privilege_type.lastIndexOf('_')
        return this.privilege_type.substr(position + 1)
      } else {
        return '0'
      }
    },
  }
}
</script>
